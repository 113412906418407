import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import AppButtonsAllPrimaryPreview from "../../../library/components/app-button/react/_preview/primary"
import AppButtonsAllBasicPreview from "../../../library/components/app-button/react/_preview/basic"
import AppButtonsAllOutlinePreview from "../../../library/components/app-button/react/_preview/outline"

import AppButtonsHeroComponent from "../../../library/components/app-button/react/_preview/AppButtonsHeroComponent"

// All App
// Large
const AppButtonLgPrimaryAppleJsx = require("!!raw-loader!../../../library/components/app-button/react/lg/primary-apple.jsx")
const AppButtonLgPrimaryAppleHTML = require("!!raw-loader!../../../library/components/app-button/html/lg/primary-apple.html")

const AppButtonLgPrimaryGoogleJsx = require("!!raw-loader!../../../library/components/app-button/react/lg/primary-google.jsx")
const AppButtonLgPrimaryGoogleHTML = require("!!raw-loader!../../../library/components/app-button/html/lg/primary-google.html")

const AppButtonLgBasicAppleJsx = require("!!raw-loader!../../../library/components/app-button/react/lg/basic-apple.jsx")
const AppButtonLgBasicAppleHTML = require("!!raw-loader!../../../library/components/app-button/html/lg/basic-apple.html")

const AppButtonLgBasicGoogleJsx = require("!!raw-loader!../../../library/components/app-button/react/lg/basic-google.jsx")
const AppButtonLgBasicGoogleHTML = require("!!raw-loader!../../../library/components/app-button/html/lg/basic-google.html")

const AppButtonLgOutlineAppleJsx = require("!!raw-loader!../../../library/components/app-button/react/lg/outline-apple.jsx")
const AppButtonLgOutlineAppleHTML = require("!!raw-loader!../../../library/components/app-button/html/lg/outline-apple.html")

const AppButtonLgOutlineGoogleJsx = require("!!raw-loader!../../../library/components/app-button/react/lg/outline-google.jsx")
const AppButtonLgOutlineGoogleHTML = require("!!raw-loader!../../../library/components/app-button/html/lg/outline-google.html")

// Base
const AppButtonBasePrimaryAppleJsx = require("!!raw-loader!../../../library/components/app-button/react/base/primary-apple.jsx")
const AppButtonBasePrimaryAppleHTML = require("!!raw-loader!../../../library/components/app-button/html/base/primary-apple.html")

const AppButtonBasePrimaryGoogleJsx = require("!!raw-loader!../../../library/components/app-button/react/base/primary-google.jsx")
const AppButtonBasePrimaryGoogleHTML = require("!!raw-loader!../../../library/components/app-button/html/base/primary-google.html")

const AppButtonBaseBasicAppleJsx = require("!!raw-loader!../../../library/components/app-button/react/base/basic-apple.jsx")
const AppButtonBaseBasicAppleHTML = require("!!raw-loader!../../../library/components/app-button/html/base/basic-apple.html")

const AppButtonBaseBasicGoogleJsx = require("!!raw-loader!../../../library/components/app-button/react/base/basic-google.jsx")
const AppButtonBaseBasicGoogleHTML = require("!!raw-loader!../../../library/components/app-button/html/base/basic-google.html")

const AppButtonBaseOutlineAppleJsx = require("!!raw-loader!../../../library/components/app-button/react/base/outline-apple.jsx")
const AppButtonBaseOutlineAppleHTML = require("!!raw-loader!../../../library/components/app-button/html/base/outline-apple.html")

const AppButtonBaseOutlineGoogleJsx = require("!!raw-loader!../../../library/components/app-button/react/base/outline-google.jsx")
const AppButtonBaseOutlineGoogleHTML = require("!!raw-loader!../../../library/components/app-button/html/base/outline-google.html")

const AppButtonsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BasicAppButtons",
      title: "Basic app buttons",
      active_tab: 1,
    },
    {
      component_name: "PrimaryAppButtons",
      title: "Primary",
      active_tab: 1,
    },
    {
      component_name: "OutlinedAppButtons",
      title: "Outlined",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS App Buttons - WindUI Component Library"
        ogtitle="Tailwind CSS App Buttons - WindUI Component Library"
        description="App button components are interactive elements that direct users to platforms like Google Play Store and Apple App Store. Built with TailwindCSS by WindUI."
        ogdescription="App button components are interactive elements that direct users to platforms like Google Play Store and Apple App Store. Built with TailwindCSS by WindUI."
        url="components/app-buttons/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS App Buttons, App Buttons, App Button Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">App Buttons</h1>
          <p>
            App store buttons are interactive elements that, when clicked,
            direct users to download or access mobile applications from
            platforms like Google Play Store and Apple App Store.
          </p>

          <div className="flex min-h-60 w-full justify-start rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 md:justify-center md:p-16">
            <section
              className="not-prose m-auto max-w-full"
              aria-multiselectable="false"
            >
              <AppButtonsHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="BasicAppButtons">Basic App Buttons</h3>
          <PreviewBlock
            id="BasicAppButtons"
            HtmlComponent={AppButtonLgBasicAppleHTML.default}
            JsxComponent={AppButtonLgBasicAppleJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex min-h-72 w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <AppButtonsAllBasicPreview
                AppButtonLgBasicApple={
                  activeTabs[0].active_tab === 1
                    ? AppButtonLgBasicAppleHTML.default
                    : AppButtonLgBasicAppleJsx.default
                }
                AppButtonLgBasicGoogle={
                  activeTabs[0].active_tab === 1
                    ? AppButtonLgBasicGoogleHTML.default
                    : AppButtonLgBasicGoogleJsx.default
                }
                AppButtonBaseBasicApple={
                  activeTabs[0].active_tab === 1
                    ? AppButtonBaseBasicAppleHTML.default
                    : AppButtonBaseBasicAppleJsx.default
                }
                AppButtonBaseBasicGoogle={
                  activeTabs[0].active_tab === 1
                    ? AppButtonBaseBasicGoogleHTML.default
                    : AppButtonBaseBasicGoogleJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="PrimaryAppButtons">Primary App Buttons</h3>
          <PreviewBlock
            id="PrimaryAppButtons"
            HtmlComponent={AppButtonLgPrimaryAppleHTML.default}
            JsxComponent={AppButtonLgPrimaryAppleJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex min-h-72 w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <AppButtonsAllPrimaryPreview
                AppButtonLgPrimaryApple={
                  activeTabs[1].active_tab === 1
                    ? AppButtonLgPrimaryAppleHTML.default
                    : AppButtonLgPrimaryAppleJsx.default
                }
                AppButtonLgPrimaryGoogle={
                  activeTabs[1].active_tab === 1
                    ? AppButtonLgPrimaryGoogleHTML.default
                    : AppButtonLgPrimaryGoogleJsx.default
                }
                AppButtonBasePrimaryApple={
                  activeTabs[1].active_tab === 1
                    ? AppButtonBasePrimaryAppleHTML.default
                    : AppButtonBasePrimaryAppleJsx.default
                }
                AppButtonBasePrimaryGoogle={
                  activeTabs[1].active_tab === 1
                    ? AppButtonBasePrimaryGoogleHTML.default
                    : AppButtonBasePrimaryGoogleJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="OutlinedAppButtons">Outlined App Buttons</h3>

          <PreviewBlock
            id="OutlinedAppButtons"
            HtmlComponent={AppButtonLgOutlineAppleHTML.default}
            JsxComponent={AppButtonLgOutlineAppleJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex min-h-72 w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <AppButtonsAllOutlinePreview
                AppButtonLgOutlineApple={
                  activeTabs[2].active_tab === 1
                    ? AppButtonLgOutlineAppleHTML.default
                    : AppButtonLgOutlineAppleJsx.default
                }
                AppButtonLgOutlineGoogle={
                  activeTabs[2].active_tab === 1
                    ? AppButtonLgOutlineGoogleHTML.default
                    : AppButtonLgOutlineGoogleJsx.default
                }
                AppButtonBaseOutlineApple={
                  activeTabs[2].active_tab === 1
                    ? AppButtonBaseOutlineAppleHTML.default
                    : AppButtonBaseOutlineAppleJsx.default
                }
                AppButtonBaseOutlineGoogle={
                  activeTabs[2].active_tab === 1
                    ? AppButtonBaseOutlineGoogleHTML.default
                    : AppButtonBaseOutlineGoogleJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            App button components make it easy for users to find and download
            apps, boosting accessibility and user interaction.
          </p>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Buttons come in 3 styles (basic, primary and outline) They can
            contain text and leading icons. All variations come in 2 sizes:
          </p>
          <ul>
            <li>
              <strong>Large:</strong> 4rem height (64px), 0.875rem (14px)
              horizontal padding and 1rem (16px) font size. Icons on large
              buttons are 2.5rem (40px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
            <li>
              <strong>Base:</strong> 3rem height (48px), 0.875rem (14px)
              horizontal padding and 0.875rem (14px) font size. Icons on base
              buttons are 2rem (32px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              App buttons does not require any additional accessibility
              settings. For the included icons, use an{" "}
              <code>&lt;aria-label&gt;</code> and <code>&lt;role&gt;</code> to
              describe the icon.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default AppButtonsPage
